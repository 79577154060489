<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="transparent" tile elevation="0" dense>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="$emit('openDesc', false)" elevation="0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div v-html="project" class="pa-10 description"></div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "FullProjectDescription",
  props: {
    project: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style>
.description {
  max-width: 60%;
  margin: auto;
}
</style>
